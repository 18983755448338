/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import axios from 'axios';
// import { getToken } from '@/utils/auth';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = 'https://api.joyballet.cn'; // 填写域名
// axios.defaults.baseURL = 'https://apitest.joyballet.cn'; // 填写域名

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    // console.log(config.data)
    config.headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      // Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    return config;
  },
  // eslint-disable-next-line handle-callback-err
  () => Promise.reject(err),
);

// 响应拦截器即异常处理
axios.interceptors.response.use(response => response, (err) => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        // eslint-disable-next-line no-console
        console.log('错误请求');
        break;
      case 401:
        // eslint-disable-next-line no-console
        console.log('未授权，请重新登录');
        break;
      case 403:
        console.log('拒绝访问');
        break;
      case 404:
        console.log('请求错误,未找到该资源');
        break;
      case 405:
        console.log('请求方法未允许');
        break;
      case 408:
        console.log('请求超时');
        break;
      case 500:
        console.log('服务器端出错');
        break;
      case 501:
        console.log('网络未实现');
        break;
      case 502:
        console.log('网络错误');
        break;
      case 503:
        console.log('服务不可用');
        break;
      case 504:
        console.log('网络超时');
        break;
      case 505:
        console.log('http版本不支持该请求');
        break;
      default:
        console.log(`连接错误${err.response.status}`);
    }
  } else {
    console.log('连接到服务器失败');
  }
  return Promise.resolve(err.response);
});

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params) {
  // console.log(params)
  params.token = sessionStorage.getItem('token');
  // console.log(params.token);
  return new Promise((resolve, reject) => {
    axios.get(url, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  console.log(data);
  // data.token = getToken('SET_TOKEN');
  if (url !== '/api/app/account/login') {
    data.token = sessionStorage.getItem('token');
  }

  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  // data.token = getToken('SET_TOKEN');
  data.token = sessionStorage.getItem('token');
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, data = {}) {
  // data.token = getToken('SET_TOKEN');
  params.token = localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    axios.delete(url, { data })
      .then((response) => {
        resolve(response.data);
      }, (err) => {
        reject(err);
      });
  });
}

/**
* 下面是获取数据的接口
*/
/**
* 测试接口
* 名称：exam
* 参数：paramObj/null
* 方式：fetch/post/patch/put
*/
export const server = {
  fetch,
  post,
  patch,
  put,
  del,
};
